import React from "react";
import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import CheckboxGroup from "./CheckboxGroup";
import RadioGroup from "./RadioGroup";
import File from '../File';
import TemplateInput from './TemplateInput';
import {schema, backUrl, docUrl, test_info } from '../../App/constants';
import BasicTable from '../basic-elements/BasicTable';
import Modal from '../basic-elements/Modal';
import '../../assets/css/builds.css'
import { RemoveIcon } from "../Icons";
import { toast } from "react-toastify";
import Plot from "react-plotly.js";
import CymaniiPowerData from "./PowerData";

const InputAdapter = props => {
  var { title,
        items,
        type,
        name,
        choices } = props.set;
  var {onChange, project, onUpload, removeFile } = props;

  const isConditional = Boolean(props.set.conditional);
  const conditionMet = isConditional && props.set.conditional === project.process_type
  if (isConditional && !conditionMet) { return null; }

  if (!project) {return null;}

  function grabFields(fields){
    console.log(fields)
    var f = fields.set.title.split(',')
    var questions = []
    f.forEach((q) => {
      var n = q.toLowerCase()
      n.indexOf(' ') >= 0 ? n = n.split(' ').join('_') : n = n
      var ff = {
        title: q,
        name: n
      }
      questions.push(ff)
    })
    console.log(questions)
    return questions
  }

  return(
    <div className="input-adapater-wrapper">
    { type === "date" && <Input value={props.project[name]}
          name={name}
          label= {props.set.title}
          onChange={props.onChange}
          type="date" /> }
    { false && "TODO --> make a separate onChange fuction for file" }
    { type === 'file' && <File label={props.set.title }
                               name = {props.set.name }
                               value = {props.project[name] }
                               removeFile = {props.removeFile }
                               onChange = {props.onUpload }/> }
    { type === "text" && <Input value={props.project[name]} name={props.set.name} label={props.set.title} onChange={props.onChange} /> }
    { type === "comment" && <TextArea value={props.project[name]} name={props.set.name} label={props.set.title} onChange={props.onChange}/>}
    { type === "dropdown" && <Select value={props.project[name]} name={props.set.name} label={props.set.title} onChange={props.onChange} options={props.set.choices.map(a => a.text)} />}
    { type === "checkbox" && <CheckboxGroup question={props.set.title}
      options={ props.set.choices.map(a => ({ checked: props.project[a.value], label: a.text, name: a.value, onChange: props.onChange}) )      }
        />}
    { type === "multipletext" &&
        <div>
            <h4 style={{fontWeight: "normal"}}>{title}</h4>
        { items.map( (item, index) =>
            <div>
              <Input isLast = {index == items.length - 1}
                     style="multipart"
                     name={item.name}
                     label= {item.title}
                     onChange={onChange}
                     value= {props.project[item.name]}
                     type="text" />
            </div>
            ) }
        </div>

    }
    {
      type === "radiogroup" &&
        <RadioGroup
          name  = {name}
          title = {title}
          radioButtons = {choices}
          onChange={onChange}
          value = { project[name]}
        />
    }
    {
      type === 'template' &&
      <TemplateInput
        templates = { props.templates }
        changeTemplate = {props.changeTemplate }
        openTemplateModal={ props.openTemplateModal }
        setTemplate={ props.setTemplate }
      />
    }
    {
      type === 'custom1' &&
    <Custom {...props}/>
    }
    { type === 'machines' &&
    <Machines {...props} />
    }

    {
      type === 'NONDEST' &&
        <div className="form-row-wrapper">
          <h4 style={{marginLeft: -2}}>Non Destructive Tests: </h4>
          <ShowNTests
            addReport={props.addReport}
            tests={props.project.tests || []}
          />
        </div>
    }
    {
      type === 'DEST' &&
        <div className="form-row-wrapper">
          <h4 className="parts-title">Parts: </h4>
        <ShowVTests
          showTests={false}
          buildId={props.project.id}
          parts={props.project.parts || []}
        />
      </div>
    }
    {
      type === 'DEST2' &&
        <div className="form-row-wrapper">
          <h4 className="parts-title">Parts/Tests: </h4>
        <ShowVTests
          showTests={true}
          buildId={props.project.id}
          build={props.project}
          parts={props.project.parts || []}
        />
      </div>
    }
    {
      type === 'PART_PARAMS' &&
        <div className="form-row-wrapper">
          <h4 className="parts-title">Parts: </h4>
        <ShowVTests
          bigProps={props}
          project={props.project}
          showParams={true}
          buildId={props.project.id}
          parts={props.project.parts || []}
        />
      </div>
    }

    {
      type === 'parts' &&
        <div className="form-row-wrapper">
          <h4 className="parts-title">Parts: </h4>
        <ShowVTests
          bigProps={props}
          project={props.project}
          showParams={true}
          buildId={props.project.id}
          parts={props.project.parts || []}
          tempQuestions={grabFields(props)}
        />
      </div>
    }
      </div>
  );
}

class Machines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      machines: [],
      selectedMachine: null
    }
  }

  componentWillMount() {
    // console.log('input adapter props', this.props);
    fetch(docUrl + 'renishaw')
    .then( a => a.json())
    .catch(e => console.log('e', e))
    .then( a => this.setState({machines: a}, () => {
        // console.log(this.state);
        var machine = this.props.project.custom_33
        if ( machine ) {
          var selectedMachine = this.state.machines.find(m => m.name === machine)
          this.setState({selectedMachine})
        }
    }))
  }

  handleChange(v) {
    var selectedMachine = this.state.machines.find(m => m.name === v.target.value)
    this.setState({selectedMachine}, () => console.log(this.state));

    this.props.onChange(v)
  }

  handleBuildChange(v) {
    // console.log(v.target.value);
    // console.log(this.state.selectedMachine);
    var buildId = this.state.selectedMachine.builds.find(b => b.N === v.target.value).Id;
    var machineId = this.state.selectedMachine.id;
    this.props.onChange(v);
    fetch(docUrl + `renishaw/${machineId}/${buildId}`)
    .then( a => a.json())
    .catch(e => console.log('e', e))
    .then( v => {
      // console.log(v);
      if ( !v) alert('no info')
      else {
        var v1 = {
          target: {
          name: 'elongation',
          value: v.mean
          }
        }
        var v2 = {
          target: {
          name: 'date_inspect',
          value: v.stdev
          }
        }
        var v3 = {
          target: {
          name: 'inspector',
          value: v.max
          }
        }
        this.props.onChange(v1);
        this.props.onChange(v2);
        this.props.onChange(v3);
      }
    });
  }

  render() {
    return(
        <div>
        <Select
        name='custom_33'
        onChange={(v) => this.handleChange(v)}
        value={this.props.project.custom_33}
        label='InfiniAM Machine Id'
        options={this.state.machines && this.state.machines.map(a => `${a.name}`)}
        />

        <Select
          name='custom_32'
          onChange={(v) => this.handleBuildChange(v)}//this.props.onChange}
          value={this.props.project.custom_32}
          label='Build'
          options={this.state.selectedMachine && this.state.selectedMachine.builds.map(z => z.N)}
        />
        </div>
    );
  }
}

class Custom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      machines: [],
      materials: [],
      allMaterials: [],
      p: null // the id of the material
    }

  }

  componentDidMount() {
    this.getMaterials();
    this.getReni();
  }

  getReni() {
    fetch(docUrl + 'renishaw')
    .then( a => a.json())
    .catch(e => console.log('e', e))
    .then( a => this.setState({machines: a}))
  }


  async getP() {
     var token = JSON.parse(localStorage["appState"]).user.auth_token;
    fetch(backUrl + 'api/project', {
      method: "GET",
      headers: new Headers({
        'Authorization': `Bearer ${token}`
      })
    })

  }

  getMaterials() {
     var token = JSON.parse(localStorage["appState"]).user.auth_token;
    fetch(backUrl + 'api/materials', {
      method: "GET",
      headers: new Headers({
        'Authorization': `Bearer ${token}`
      })
    })
    .then(a => a.json())
    .then( a => {
      // console.log(a);
      // console.log(this.props.project.feedstock);
      setTimeout( () => {
      var filtered = a.filter(b => b.material_type == this.props.project.feedstock)
      // console.log(filtered);
      this.setState({materials: filtered, allMaterials: a});
      this.setState({p: this.props.project.material_id}, () => console.log(this.state));
      }, 1000)
    });

  }

  onChange(e) {
    // console.log('asdf', e.target.value);
    // console.log('asdf2', e.target.name);
    // console.log(this.state.allMaterials);
    var ok = {
      target: {
        value: this.state.allMaterials.find(a => a.id == e.target.value).material_type,
        name: 'feedstock'
      }
    };

    this.props.onChange(e);
    this.props.onChange(ok);
    this.setState({p: e.target.value});
  }

  render() {
  var { props } = this;
  var mat = this.state.p && this.state.allMaterials.filter(m => m.id == this.state.p)[0];
  var matType = mat && this.state.p && mat.material_type;

  return(
    <div>
      { props.machine ? (
        <Select
        name='custom_33'
        onChange={props.onChange}
        value={props.project.custom_33}
        label='InfiniAM Machine Id'
        options={this.state.machines.map(a => `${a.name} (${a.machineType})`)}
        />
      ) :(
      props.project &&
        <div>
          { props.project.material_id &&
              <div style={{marginLeft: 30}}>
              <label style={{marginRight: 135}}>Select Material</label>
              <select value={this.props.project.material_id }name='material_id' onChange={(e) => this.onChange(e)}style={{width: 400}}>
                <option>Select</option>
                { this.state.allMaterials.map(mat => <option value={mat.id}>{mat.name}</option>)}
              </select>
              </div>
          }
          { !props.project.material_id &&
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 30  }}>
              <div style={{display: 'flex'}}>
              <label style={{marginRight: 135}}>Select Material</label>
              <select name='material_id' onChange={(e) => this.onChange(e)}style={{width: 400}}>
                <option>Select</option>
                { this.state.allMaterials.map(mat => <option value={mat.id}>{mat.name}</option>)}
              </select>
               </div>
      <div style={{display:'flex', flexDirection: 'column'}}>
      </div>
            </div>
          }
        </div>
      ) }
`   </div>
  );
  }
}

export default InputAdapter;

const GroupA = props => {
  // console.log('propsss', props);
  return(
  <div style={{marginBottom: 20, marginLeft: '30px', display: 'flex', flexDirection: 'column'}}>
        <label>{props.t.label}</label>
    <div style={{display: 'flex'}}>
    { false && props.t.questions.map((q,i) =>
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginRight: '23px'}}>
      <label>{ q.label }</label>
      <input value={props.m[props.t.questions[i].field]} style={{width: 200}}onChange={(e) => props.cool(e, i)}/>
      </div>
    )}
    </div>
  </div>
)}


class PartModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      part: {},
    }

    this.testTypes = Object.keys(test_info)
    this.questions = test_info

  }

  componentDidMount() {
    console.log('inPopup')
    if (this.props.partId === -1 ) { return; }
    fetch(`${backUrl}/parts/${this.props.partId}`, {
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(z => z.json())
      .then(x => {
        console.log('in this call')
        console.log(x)
        this.setState({part:x})
      })
      .catch(e => alert(e));
  }

  handleChange(e) {
    this.setState({
      part: {
        ...this.state.part,
        [e.target.name]: e.target.value
      }
    });
  }

  handleTestChange(e) {
    let newTest;
    if (!this.state.part.test) {
      newTest = {
        [e.target.name]: e.target.value
      };
      this.setState({
        part: {
          ...this.state.part,
          test: newTest
        }
      }, () => console.log(this.state));
    } else {
      newTest = {
        ...this.state.part.test,
        [e.target.name]: e.target.value
      }
      this.setState({
        part: {
          ...this.state.part,
          test: newTest
        }
      })
    }
  }

  createNew() {
    const { afrl_location, y_location, z_location, part_id, test_temperature,
    laser_power, laser_speed, hatch_distance, build_plate_x, build_plate_y } = this.state.part;
    const energy_density = (parseFloat(this.state.part.laser_power)/(parseFloat(this.state.part.hatch_distance) * parseFloat(this.state.part.laser_speed))).toFixed(4)
    fetch(`${backUrl}builds/${this.props.buildId}/parts`, {
      method: 'POST',
      body: JSON.stringify({
        afrl_location,
        y_location,
        z_location,
        test_temperature,
        part_id,
        laser_power,
        laser_speed,
        hatch_distance,
        energy_density,
        build_plate_x,
        build_plate_y
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Accept": "application/json"
      }
    })
      .then(v => v.json())
      .then(v => {
          const testId = v.test.id;
          return fetch(`${backUrl}tests/${testId}`, {
            method: 'PUT',
            body: JSON.stringify(this.state.part.test),
            headers: {
              "Content-Type": "application/json",
              "Authorization": 'Bearer ' + localStorage.getItem('token'),
              "Accept": "application/json"
            }
          })
      })
      //.then(z => window.location.reload())
      .then(z => this.props.close())
      .catch(e => alert(e));
  }

  handleSave() {
    const partId = this.state.part.id;
    const { afrl_location, y_location, z_location, part_id, test_temperature,
      laser_power, laser_speed, hatch_distance, energy_density, build_plate_x, build_plate_y } = this.state.part;
    let body = {
        afrl_location,
        y_location,
        z_location,
        part_id,
        test_temperature,
        laser_power,
        laser_speed,
        hatch_distance,
        energy_density,
        build_plate_x,
        build_plate_y
    }
    this.props.questions.forEach(q => {
      body[q.name] = this.state.part[q.name]
    })

    body["energy_density"] = (parseFloat(this.state.part.laser_power)/(parseFloat(this.state.part.hatch_distance) * parseFloat(this.state.part.laser_speed))).toFixed(4)
    
    if (this.props.partId === -1) {
      this.createNew();
      return;
    }
    console.log(body)
    fetch(`${backUrl}parts/${partId}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Accept": "application/json"
      }
    })
      .then(z => {
        console.log(z)
        let testId
        // is there a testId
        if ( this.state.part.test && this.state.part.test.id !== undefined ) {
          testId = this.state.part.test.id
          fetch(`${backUrl}tests/${testId}`, {
            method: 'PUT',
            body: JSON.stringify(this.state.part.test),
            headers: {
              "Content-Type": "application/json",
              "Authorization": 'Bearer ' + localStorage.getItem('token'),
              "Accept": "application/json"
            }
          })
            .then(x => console.log(x))
            .then(x => this.props.close())
            .catch(x => alert(x))
        } else if (this.state.part.test && this.state.part.test.id === undefined ){
            var grab = {
              build_id: this.props.buildId,
              test: this.state.part.test,
              part_id: this.state.part.id
            }
            fetch(`${backUrl}tests/`, {
              method: 'POST',
              body: JSON.stringify(grab),
              headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Accept": "application/json"
              }
            })
              .then(x => console.log(x))
              .then(x => this.props.close())
              .catch(x => alert(x))
        }
      })
      .catch(z => {
        console.log(z)
        console.log('here')
      });
  }

  handleCancel() {
    this.props.close();
  }

  generateAFRLGrid(selected, callback) {
    var gridItems = []

    for(let i = 1; i <= 9; i++){
      var cssClass = "afrl_location_selector";
      if(i == selected) cssClass += " afrl_selected_location"
      gridItems.push(<div className={cssClass} onClick={() => callback({target:{name:"afrl_location", value:i}})}>{i}</div>)
    }

    return gridItems
  }

  render() {

    var energyDensity = (parseFloat(this.state.part.laser_power)/(parseFloat(this.state.part.hatch_distance) * parseFloat(this.state.part.laser_speed))).toFixed(4)

    let otherQuestions = [];
    (this.state.part.test && this.state.part.test.test_type) ?
      otherQuestions = this.questions[this.state.part.test.test_type] : otherQuestions = [];

    let ancillary_data = null

    
    if(this.state.part.test != null){
      if(this.state.part.test.ancillary_data != null){
        ancillary_data = JSON.parse(this.state.part.test.ancillary_data)
      }
    }

    let power_data = null
    if(this.state.part.test != null){
      if(this.state.part.test.power_data != null){
        power_data = JSON.parse(this.state.part.test.power_data)
      }
    }

    return(
      <div className="parts-add-modal">
        <Input
          name={'part_id'}
          value={this.state.part.part_id}
          label={'Part ID'}
          onChange={(e) => this.handleChange(e)}
        />
        <div className="form-row">
          <label className="form-label">Location</label>
          <div className="afrl_location_container">
            {this.generateAFRLGrid(this.state.part.afrl_location, (e) => this.handleChange(e))}
          </div>
        </div>
        {/* TODO: Check which company the user is a part of */}
        {true && <Input
          name={'laser_power'}
          value={this.state.part.laser_power}
          label={'Laser Power (W)'}
          onChange={(e) => this.handleChange(e)}
        />}
        {true && <Input
          name={'laser_speed'}
          value={this.state.part.laser_speed}
          label={'Laser Speed (mm/s)'}
          onChange={(e) => this.handleChange(e)}
        />}
        {true && <Input
          name={'hatch_distance'}
          value={this.state.part.hatch_distance}
          label={'Hatch Distance (mm)'}
          onChange={(e) => this.handleChange(e)}
        />}
        {true && <Input
          name={'energy_density'}
          value={!isNaN(energyDensity) ? energyDensity : "Cannot calculate from given data"}
          label={'Energy Density (J/mm^2)'}
        />}
        {true && <Input
          name={'build_plate_x'}
          value={this.state.part.build_plate_x}
          label={'Build Plate X Location'}
          onChange={(e) => this.handleChange(e)}
        />}
        {true && <Input
          name={'build_plate_y'}
          value={this.state.part.build_plate_y}
          label={'Build Plate Y Location'}
          onChange={(e) => this.handleChange(e)}
        />}
        {this.props.showTests && <Input
          name={'test_temperature'}
          value={this.state.part.test_temperature}
          label={'Test Temperature (deg C)'}
          onChange={(e) => this.handleChange(e)}
        />}
        { this.props.showTests && <Select
          name='test_type'
          onChange={(e) => this.handleTestChange(e)}
          value={this.state.part.test && this.state.part.test.test_type}
          label='Test Type'
          options={this.testTypes}
        /> }
        { this.props.showTests &&
          otherQuestions.map(q =>
            <Input
              label={q.label}
              name={q.name}
              value={this.state.part.test[q.name]}
              onChange={(e) => this.handleTestChange(e)}
            />
          )
        }
        {
          this.props.showTests && this.state.part.test && this.state.part.test.test_type == "Stress-Strain" && ancillary_data != null &&
          <div>
          <Plot 
            data={[
              {
                x: ancillary_data.times,
                y: ancillary_data.true_stress,
                mode: 'lines',
                name: "True Stress (MPa)"
              },
              {
                x: ancillary_data.times,
                y: ancillary_data.eng_stress,
                mode: 'lines',
                name: "Eng Stress (MPa)"
              }
            ]}
            layout={{
              title: 'Stress Over Time',
              xaxis: {
                showgrid:true,
                title: "Time (S)"
              },
              yaxis: {
                showgrid: true,
                title: "Stress (MPa)"
              }
            }}
          />

          <Plot 
            data={[
              {
                x: ancillary_data.times,
                y: ancillary_data.load,
                mode: 'lines',
                name: "Load (N)"
              },
            ]}
            layout={{
              title: 'Load Over Time',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Time (S)"
              },
              yaxis: {
                showgrid: true,
                title: "Load (N)"
              }
            }}
          />
          <Plot 
            data={[
              {
                x: ancillary_data.column_Q,
                y: ancillary_data.eng_stress,
                mode: 'lines',
                name: "Engineering Stress/Strain"
              },
              {
                x: ancillary_data.column_S,
                y: ancillary_data.true_stress,
                mode: 'lines',
                name: "True Stress/Strain"
              },
            ]}
            layout={{
              title: 'Stress v. Strain',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Strain (mm/mm)"
              },
              yaxis: {
                showgrid: true,
                title: "Stress (MPa)"
              }
            }}
          />
          </div>
        }
        {
          this.props.showParams &&
            this.props.questions.map(q => {
              // console.log(q);
              return(
                <Input
                  value={this.state.part[q.name]}
                  name={q.name}
                  label={q.title}
                  onChange={(e) => this.handleChange(e)}
                />
              )
            })
        }
        <button onClick={() => this.handleSave()}>Save</button>
        <button onClick={() => this.handleCancel()}>Cancel</button>
    </div>
    );
  }

}

/**
 *  Get all of the parts for a given build
 *
 */
class PartParams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parts: []
    }
  }

  componentDidUpdate() {
    if ( this.props.buildId) {
    fetch(`${backUrl}builds/${this.props.buildId}/parts`)
      .then(r => r.json())
      .then(j => console.log(j))
      .catch(e => console.error(e));
    }
  }

  render() {
    return(
      <div>
        <p>Hi {this.props.buildId}</p>
      </div>
    );
  }
}

class ShowVTests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showNslUpload: false,
      nslFile: null,
      questions: props.tempQuestions ? props.tempQuestions : [],
      parts: props.parts,
      buildId: props.buildId,
      selectedProvider: "NSL",
      afrlPretrigger: 0.0,
      afrlPoundsPerVolt: 1500.0,
      afrlDiameter: .25,
      afrlStrokePosition: 0.042,
      afrlStrokeGuageLength: 1.5,
      power_threshold: 85
    }
  }

  componentDidMount(){
    console.log('Attempting to update parts')

              fetch(`${backUrl}builds/${this.props.buildId}`, {
                headers: {
                  "Authorization": 'Bearer ' + localStorage.getItem('token')
                }
              })
              .then(response => response.json())
              .then(json => this.setState({parts: json.parts}))
              .catch(x => alert(x))  
    console.log(this.state)
  }

  componentDidUpdate(previousProps, previousState) {
    // console.log(schema);
    // console.log('inVTests')
    if (this.props.project && this.props.project.process_type) {
      // console.log(this.props.project.process_type);
      const schemaSections = schema.pages[3].elements.filter(e =>
        {
        return e.conditional === this.props.project.process_type
        }
      );

      if ( this.state.questions.length === 0 ) {
        this.setState({
          questions: schemaSections || []
        });
      }
    }
  }

  handleClick(p) {
    const partId = p.part.id;
    this.setState({show: partId});
  }

  addPart() {
    this.setState({
      show: -1
    });
  }

  render() {

    const testingProviders = [
      "NSL",
      "Raw Tensile Data",
      "Part ID Upload",
      "Power Data"
    ];

    const buttonText = this.props.showTests
      ? 'Add Part / Test'
      : 'Add Part';

    const labelText = this.state.selectedProvider + " testing file"

    //Force a reload of chart data if build changes
    if(this.state.buildId != this.props.buildId){
      this.setState({buildId: this.props.buildId})
      return <p>... Loading build data</p>
    }

    return(
      <div className="parts-table-wrapper">
        <div className="parts-table-button-row">
          {!this.props.showParams &&
          <button
            className="parts-add-button"
            onClick={() => this.addPart()}
          >
            {buttonText}
          </button>}
          {!this.props.showParams && this.props.showTests &&
          <button
            className="parts-add-button"
            onClick={() => {
              this.setState({showNslUpload: true, nslFile: null});
            }}
          >
            Upload Testing Data
          </button>
          }
        </div>
        
      <BasicTable>
        {/* NSL Testing Data Upload Modal */}
        <Modal
          isOpen = {this.state.showNslUpload}
          onClose = {() => this.setState({showNslUpload: false})}
        >
          <div className="parts-add-modal">
            <h4>Upload Testing Data</h4>
            <Select options={testingProviders} onChange={e => {
              console.log(e.target.value)
              this.setState({selectedProvider: e.target.value})
            }} value={this.state.selectedProvider}
            label="Select Testing Provider"
            />
            <File label={labelText}
              name = {this.state.nslFile != null ? this.state.nslFile.name : null }
              value = {this.state.nslFile != null ? this.state.nslFile : null}
              onChange = {(e)=>{
               const files = e.target.files;
               if(!files || files.length === 0){
                console.log("No file selected");
                return;
               }

               const file = files[0]
               this.setState({nslFile: file})
              }}/>
            {this.state.selectedProvider == "Raw Tensile Data" ? <div>
              Initial Conditions:
              <Input label="Pretrigger" value={this.state.afrlPretrigger} onChange = {(e)=>{
                this.setState({afrlPretrigger: e.target.value})
              }}/>
              <Input label="lbs/V" value={this.state.afrlPoundsPerVolt} onChange = {(e)=>{
                this.setState({afrlPoundsPerVolt: e.target.value})
              }}/>
              <Input label="Diameter (in)" value={this.state.afrlDiameter} onChange = {(e)=>{
                this.setState({afrlDiameter: e.target.value})
              }}/>
              <Input label="Stroke Position (in/V)" value={this.state.afrlStrokePosition} onChange = {(e)=>{
                this.setState({afrlStrokePosition: e.target.value})
              }}/>
              <Input label="Stroke Gauge Length (in)" value={this.state.afrlStrokeGuageLength} onChange = {(e)=>{
                this.setState({afrlStrokeGuageLength: e.target.value})
              }}/>
            </div>: <div/>}
            {this.state.selectedProvider == "Power Data" ? <div>
              Set Injection Cycle Power Thrshold
              <Input label="Power Threshold (Amps)" value={this.state.power_threshold} onChange = {(e)=>{
                this.setState({power_threshold: e.target.value})
              }}/>

              <Input label="Line Voltage" value={this.state.line_voltage} onChange = {(e)=>{
                this.setState({line_voltage: e.target.value})
              }}/>
            </div>: <div/>}
            {this.state.selectedProvider == "Power Data" && <p>Note: Power Data uploads can take a long time due to the quantity of data, please be patient after clicking upload</p>}
            <button onClick={()=>{
              if(this.state.nslFile == null){
                console.log("No File Selected")
                toast.error("No File Selected")
                return
              }

              if(this.state.selectedProvider === "NSL"){
                if(!this.state.nslFile.name.endsWith('.csv')){
                  console.log("User uploaded non csv file")
                  toast.error("You must upload a CSV file as the testing report")
                  return
                }
              }
              else if(this.state.selectedProvider === "Raw Tensile Data"){
                if(!(this.state.nslFile.name.endsWith('.csv') || this.state.nslFile.name.endsWith('.CSV'))){
                  console.log("User uploaded non csv file")
                  toast.error("You must upload a CSV file as the testing report")
                  return
                }
              }

              const reader = new FileReader()
              reader.onload = (readEvent) => {
                //File contents are stored in readEvent.target.result
                //console.log(readEvent.target.result)

                const data = {
                  build_id: this.props.buildId,
                  file_data: readEvent.target.result,
                  type: this.state.selectedProvider,
                  pretrigger: this.state.afrlPretrigger,
                  poundsPerVolt: this.state.afrlPoundsPerVolt,
                  diameter: this.state.afrlDiameter,
                  strokePosition: this.state.afrlStrokePosition,
                  strokeGuageLength: this.state.afrlStrokeGuageLength,
                  power_threshold: this.state.power_threshold,
                  line_voltage: this.state.line_voltage
                }

                fetch(`${backUrl}parts/testing_upload`, {
                  method: "POST",
                  headers: {
                    "Authorization": 'Bearer ' + localStorage.getItem('token'),
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(data)
                })
                .then(response => {
                  if(!response.ok){
                    toast.error("Server could not process provided file")
                  }

                  return response.json()
                })
                .then(data => {
                  //Once the server has processed the data, reload the page with the new data
                  fetch(`${backUrl}builds/${this.props.buildId}`, {
                    headers: {
                      "Authorization": 'Bearer ' + localStorage.getItem('token')
                    }
                  })
                  .then(response => response.json())
                  .then(json => this.setState({parts: json.parts, showNslUpload: false, buildId: 0}))
                  .catch(x => alert(x))  
                })
                .catch(error => {
                  toast.error("An error occured processing file on the server")
                })

              }
              reader.readAsText(this.state.nslFile)

            }}>
              Upload Data
            </button>
            <button onClick={()=>this.setState({showNslUpload: false})}>
              Close
            </button>
          </div>
          
        </Modal>


        {/* Part Data Entry Modal */}
        <Modal
          isOpen = { this.state.show}
          onClose = { () => this.setState({show: false })}
        >
          <PartModal
            questions={this.state.questions}
            showParams={this.props.showParams}
            showTests={this.props.showTests}
            buildId={this.props.buildId}
            partId={this.state.show}
            close={() => {
              console.log('Attempting to update parts')

              fetch(`${backUrl}builds/${this.props.buildId}`, {
                headers: {
                  "Authorization": 'Bearer ' + localStorage.getItem('token')
                }
              })
              .then(response => response.json())
              .then(json => this.setState({show: false, parts: json.parts}))
              .catch(x => alert(x))  
              // this.setState({show:false})
          }}
          />
        </Modal>
			  <thead>
          <td>Part ID</td>
          <td>Location</td>
          <td>Laser Power</td>
          <td>Energy Density</td>
          <td>Build Plate X Location</td>
          <td>Build Plate Y Location</td>
          { this.props.showTests && <td>Test Temperature</td>}
          { this.props.showTests && <td>Test Type</td>  }
          { this.props.showTests && <td></td> }
          { this.props.showParams && <td></td> }
          <td></td>
        </thead>
        <tbody>
          {this.state.parts.map(p =>
            <tr>
              <td>{p && p.part && p.part.part_id}</td>
              <td>{p && p.part && p.part.afrl_location}</td>
              <td>{p && p.part && p.part.laser_power}</td>
              <td>{p && p.part && isNaN(parseFloat(p.part.energy_density)) ? (isNaN(p.part.energy_density)? "" : p.part.energy_density) : parseFloat(p.part.energy_density).toFixed(4)}</td>
              <td>{p && p.part && p.part.build_plate_x}</td>
              <td>{p && p.part && p.part.build_plate_y}</td>
              {this.props.showTests && <td>{p.part.test_temperature}</td>}
              { this.props.showTests && <td>{p && p.test && p.test.test_type}</td> }
              { this.props.showTests && <td className="parts-table-inner-button"><a onClick={() => this.handleClick(p)} style={{cursor: 'pointer'}}>show test info</a></td> }
              { !this.props.showTests && <td className="parts-table-inner-button"><a onClick={() => this.handleClick(p)} style={{cursor: 'pointer'}}>show/edit part params</a></td> }
              <td><RemoveIcon 
                onClick={() => {
                  //Delete the part, then reload the page to show new parts
                  fetch(`${backUrl}parts/${p.part.id}`, {method: 'DELETE', headers: {"Authorization": 'Bearer ' + localStorage.getItem('token')}})
                  .then(() => {
                    console.log('Attempting to update parts')
      
                    fetch(`${backUrl}builds/${this.props.buildId}`, {
                      method: 'GET',
                      headers:{
                        "Authorization": 'Bearer ' + localStorage.getItem('token')
                      }
                    })
                    .then(response => response.json())
                    .then(json => this.setState({show: false, parts: json.parts}))
                    .catch(x => alert(x))  
                    // this.setState({show:false})
                  })
                  .catch(x => alert(x))

                }}
              /></td>
            </tr>
          )}
        </tbody>
      </BasicTable>
      <CymaniiPowerData build_id={this.state.buildId}/>
    </div>
    );
  }

}


const ShowNTests = (props) => props.tests.map(t => {
  let display;
  if (props.type === 'dest') {
    display = t.part.part_id;
    return(
    <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
      <span style={{width: 300}}>{display} | {t && t.test && t.test.test_type}</span>
    </div>
    )
  } else {
    display = t.test_type;
  }
  console.log('in ShowNTests')
  return(
  <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
    <span style={{width: 300}}>{display}</span>
    {
      t.attachment_url
        ? <div><a href={t.attachment_url}>View Report</a> <a style={{fontSize: 10, marginLeft: 12}} href={'#'} onClick={() => alert('need to add this route')} >Remove Report</a></div>
        : <div style={{display: 'flex'}}><span style={{width: 200}}>Add Report</span><Ok testId={t.id} addReport={props.addReport}/></div>
    }
  </div>
  )
}
)

const Ok = props =>
      <input style={{ border: 'none', color: '#EEF5F9', background: 'none' }}
             name={props.testId}
             onChange={props.addReport}
             type="file"/>
