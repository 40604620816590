import React, { useEffect, useState } from 'react';
import {backUrl} from '../../App/constants';
import Plot from "react-plotly.js";

const CymaniiPowerData = ({build_id}) => {

    const [power_data, setPowerData] = useState(null);
    const [loading, setLoadingState] = useState(null);

    function downloadFile(){
      setLoadingState(true)
      try{
        fetch(`${backUrl}/builds/export_power_data/${build_id}`, {
          headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then((response) => response.blob())
        .then((blob) => {
          const documentUrl = window.URL.createObjectURL(blob)
          const docLink = document.createElement("a")
          docLink.href = documentUrl
          docLink.download = "cpp_power_data.json"
          document.body.appendChild(docLink)

          docLink.click()

          document.body.removeChild(docLink);
          window.URL.revokeObjectURL(documentUrl)
          setLoadingState(false)
        })
      }
      catch(error){
        console.log(error)
      }
    }

    useEffect(()=>{
        const fetchBuild = async () => {
            setLoadingState(true)
            try{
                fetch(`${backUrl}/builds/${build_id}`, {
                    headers: {
                      "Authorization": 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(res => res.json())
                .then(data =>{
                    setPowerData(JSON.parse(data.power_data))
                })
            } catch(error){
                setPowerData(error)
                console.log(error)

            } finally{
                setLoadingState(false)
            }
            
        };

        fetchBuild();
    }, [build_id])

    if(loading) return <p>... Loading Build data</p>
    if(power_data == null) return <span/>

    return(
        <div style={{width:'100%'}}>
            <div>
              <h2 style={{width: 'fit-content', display: 'inline-block', margin: '20px 0px'}}>Uploaded power data:</h2>
              <button className="parts-add-button" style={{float: 'right', display: 'inline-block', verticalAlign: 'middle', margin: '20px'}} onClick={downloadFile}>Export CPP Data</button>
            </div>
            
            <Plot 
            data={[
              {
                x: power_data.times,
                y: power_data.amps_1a,
                mode: 'lines',
                name: "Amps (Sensor 1A)"
              },
              {
                x: power_data.times,
                y: power_data.amps_1b,
                mode: 'lines',
                name: "Amps (Sensor 1B)"
              },
              {
                x: power_data.times,
                y: power_data.amps_1c,
                mode: 'lines',
                name: "Amps (Sensor 1C)"
              },
            ]}
            layout={{
              title: 'Main Power 3 Phases',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Time (s)"
              },
              yaxis: {
                showgrid: true,
                title: "Amps"
              }
            }}
            style={{ padding: '5px' }}
          />
            <h2>Injection Molding Cylcles: <i>(Calculated based on power spiking above a {power_data.power_threshold} Amp threshold)</i></h2>
            <h2>Phase 1: {power_data.power_spikes[0]}</h2>
            <h2>Phase 2: {power_data.power_spikes[1]}</h2>
            <h2>Phase 3: {power_data.power_spikes[2]}</h2>
          <Plot 
            data={[
              {
                x: power_data.times,
                y: power_data.dc_current,
                mode: 'lines',
                name: "DC Current"
              },
            ]}
            layout={{
              title: 'DC Current / Time',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Time (s)"
              },
              yaxis: {
                showgrid: true,
                title: "Amps"
              }
            }}
            style={{ padding: '5px' }}
          />
          <Plot 
            data={[
              {
                x: power_data.times,
                y: power_data.heater_power,
                mode: 'lines',
                name: "Heater Power"
              },
            ]}
            layout={{
              title: 'Heater Power Average of 3 Phases',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Time (s)"
              },
              yaxis: {
                showgrid: true,
                title: "Power"
              }
            }}
            style={{ padding: '5px' }}
          />
          {power_data.start_time != null && <h2>Start Time: {power_data.start_time}</h2>}
          {power_data.t_0 != null && <h2>T_0 Time On: {power_data.t_0.toFixed(1)} seconds</h2>}
          {power_data.t_end != null && <h2>T_end Time Off: {power_data.t_end.toFixed(1)} seconds</h2>}
          {power_data.t_0 != null && power_data.t_end != null && <h2>T_run Total Run Time: {(power_data.t_end - power_data.t_0).toFixed(1)} seconds</h2>}
          {power_data.i_min != null && <h2>I_min Minimum current: {power_data.i_min.toFixed(1)}</h2>}
          {power_data.i_max != null && <h2>I_max Maximum current: {power_data.i_max.toFixed(1)}</h2>}
          {power_data.amp_seconds != null && <h2>Ampere-Seconds: {power_data.amp_seconds.toFixed(1)}</h2>}
          {power_data.amp_hours != null && <h2>Ampere-Hours: {power_data.amp_hours.toFixed(1)}</h2>}
          {power_data.kilowatt_hours != null && <h2>kWh: {power_data.kilowatt_hours.toFixed(1)}</h2>}
        </div>
    );

}

export default CymaniiPowerData;